<template>
  <v-row>
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="info d-none d-md-flex align-center justify-center"
    >
      <SupportArht />
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <img src="@/assets/img/arhtLogo.png" />
              <h6 class="subtitle-1">ARHT Engine customer portal</h6>
              <h2 class="font-weight-bold mt-4 white--text">
                Password reset confirmation
              </h2>

              <v-container
                style="height: 100px"
                v-if="!successConfirmation"
                class="mt-2"
              >
                <v-row
                  class="fill-height"
                  align-content="center"
                  justify="center"
                >
                  <v-col class="text-subtitle-1 text-center" cols="12">
                    Checking reset confirmation
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear
                      color="deep-purple accent-4"
                      indeterminate
                      rounded
                      height="6"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </v-container>

              <v-alert
                color="success"
                type="success"
                v-if="successConfirmation === 'success'"
                class="mt-2"
                dark
              >
                Password Reset Complete
              </v-alert>

              <v-alert
                color="error"
                type="error"
                v-if="successConfirmation === 'error'"
                class="mt-2"
                dark
              >
                Password Reset Failed
              </v-alert>

              <v-btn
                color="info"
                to="/"
                class="mr-4 mt-4"
                block
                data-testid="btn-login-page"
              >
                Go to login page
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SupportArht from "./SupportArht"

export default {
  components: {
    SupportArht,
  },

  data() {
    return {
      successConfirmation: null,
    }
  },

  mounted() {
    this.resetPasswordConfirmation()
  },

  methods: {
    resetPasswordConfirmation() {
      this.$store
        .dispatch("resetPasswordConfirmation", this.$route.params.reset_hash)
        .then(() => {
          this.successConfirmation = "success"
        })
        .catch(() => {
          this.successConfirmation = "error"
        })
    },
  },
}
</script>
